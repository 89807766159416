input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px rgb(0, 0, 0) inset !important;
}

input:-webkit-autofill
{
 -webkit-text-fill-color: white !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

